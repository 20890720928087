import { motion } from "framer-motion"


const PageLoad = ({ children }) => {
    return (<motion.div
        initial={{ opacity: 0, y: "20%" }}
        animate={{ opacity: 1, y: 0 }}
        layout
        transition={{
            duration: .3,
            type: 'spring',
            stiffness: 30,
            opacity: { ease: "linear" },
        }}
    >
        {children}
    </motion.div>)
}
export default PageLoad